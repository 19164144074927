import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';
const host = process.env.GATSBY_HOST;

const liveDemo = {
  title: 'formLiveDemoTitle',
  subTitle: 'formLiveDemoSubTitle',
  form: {
    title: 'aboutUsFormTitle',
    type: 'about',
    fields: [
      {
        type: 'firstName',
        label: 'formFirstName',
        value: ''
      },
      {
        type: 'lastName',
        label: 'formLastName',
        value: ''
      },
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      },
      {
        type: 'company',
        label: 'formCompany',
        value: ''
      },
      {
        type: 'interested',
        label: 'formInterested',
        list: [
          {
            key: 'general',
            label: 'formInterestedGeneral',
            value: false,
          },
          {
            key: 'sales',
            label: 'formInterestedSales',
            value: false,
          },
          {
            key: 'billing',
            label: 'formInterestedBilling',
            value: false,
          },
          {
            key: 'marketing',
            label: 'formInterestedMarketing',
            value: false,
          },
        ],
        value: ''
      },
      {
        type: 'message',
        label: 'formMessage',
        value: ''
      },
    ],
    button: {
      text: 'formLiveDemoButton',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class FormWorkshop extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={liveDemo} />
      </Layout>
    );
  }
}

export default withIntl(FormWorkshop);

